<template>
    <div class="mission">
        <div class="ilustracije">
            <img class="ilustracija" src="../../assets/ilustrations/genexW.png" alt="genex">
            <img class="ilustracija" src="../../assets/ilustrations/pobednikW.png" alt="pobednik">
            <img class="ilustracija" id="muzej" src="../../assets/ilustrations/muzejW.png" alt="muzej">
        </div>
        <h2 class="missionTitle">Misija i vizija Isteka</h2>
        <InfoCard text="Misija Isteka se odnosi na povezivanje studenata elektrotehnike i računarstva, 
        kao i podrška u njihovom profesionalnom, akademskom i ličnom razvoju. Sa druge strane viziju 
        predstavlja osnaživanje studenata elektrotehnike i računarstva širom Evrope da ostvare svoj 
        puni potencijal u svom akademskom, profesionalnom i društvenom životu u snažnoj i prepoznatljivoj 
        zajednici."/>
    </div>
</template>

<script>

import InfoCard from "../../components/InfoCard.vue"


export default {
  name: "Mission",
  components: {
    InfoCard
}
}

</script>

<style>

    .mission{
        background-color: #FA4952;
        height: 670px;
    }

    .missionTitle{
        margin: 0px;
        padding: 60px 0px 40px 7.5%;
        font-size: 45px;
        color: white;
    }

    .ilustracije{
        position: absolute;
    }

    .ilustracija{
        width: 32%;
    }

    #muzej{
        opacity: 0.6;
        transform: rotate(20deg);
        margin-bottom: 50px;
    }

@media screen and (max-width: 800px) {
    
    .mission{
        height: 1000px;
    }

    #muzej{
        margin-bottom: 10px;
    }
    
}

</style>