<template>
    <div class="aboutUs">
        <img src="../../assets/ilustrations/genexR.png" alt="genex" id="genexAbout">
        <div class="aboutSection">
            <h2 class="aboutTitle">O nama</h2>
            <div>
                <p class="aboutText">LK Beograd postao je član Udruženja studenata elektrotehnike Evrope 2000. 
                    godine. Broji preko 300 članova, što ga čini jednim od najbrojnijih i najorganizovanijih 
                    lokalnih komiteta u Evropi. Od svog osnivanja, više od 1000 studenata Elektrotehničkog 
                    fakulteta Univerziteta u Beogradu učestvovalo je u mnogim razmenama i stručnim seminarima 
                    širom cele Evrope.</p>
                <p class="aboutText">
                    Do danas, LK Beograd je organizovao preko 150 događaja, kako lokalnih, tako i međunarodnih. 
                    Domaćim i stranim studentima se svakodnevno pružaju prilike za lično, stručno i 
                    profesionalno usavršavanje, kao i upoznavanje različitih tradicija i kultura. Kroz 
                    razne radionice, konferencije, Kongrese i razmene, Beograd je do sada posetilo preko 1000
                    studenata iz preko 25 zemalja Evrope. Mnogi od njih se ponovo vraćaju, kako bi 
                    učestvovali na nekom događaju, ili samo posetili svoje kolege i prijatelje koje su stekli 
                    učešćem u nekom od projekata.
                </p>
                <p class="aboutText">
                    U stručne radionice uključen je veliki broj tema, kako bi svi studenti elektrotehnike, 
                    kojom god oblasti želeli da se bave, imali priliku da učestvuju u projektu i steknu nova 
                    znanja. Zahvaljujući profesorima Elektrotehničkog fakulteta, koji su uvek raspoloženi za 
                    saradnju, prethodnih godina organizovano je više uspešnih radionica iz oblasti 
                    energetike, informatike, automatike i akustike. Takođe, studentima se nudi bezbroj 
                    mogućnosti za pronalazak posla ili stručne prakse i sticanje veština koje su van okvira 
                    inženjerskog obrazovanja.
                </p>
            </div>
        </div>
        <div class="aboutImage">
            <img class="okvir" src="../../assets/okvir.png" alt="okvir">
            <img class="aboutImg" src="../../assets/EC21.png" alt="ec21">
        </div>
        
    </div>
</template>

<style>

    .aboutUs{
        display: flex;
        justify-content: space-between;
        background-color: white;
    }

    .aboutSection{
        margin-top: 5%;
        width: 50%;
    }

    .aboutTitle{  
        margin: 0px;
        padding-bottom: 40px;
        padding: 40px auto;
        text-align: center;
        font-size: 45px;
        color: #E52A30;
    }

    .aboutText{
        text-align: center;
        margin: 10px 10%;
    }

    #genexAbout{
        height: 700px;
        position: absolute;
    }

    .aboutImg{
        height: 700px;
        opacity: 0.75;
        transition: 0.4s;
    }

    .aboutImg:hover{
        opacity: 1;
    }

    .aboutImage{
        display: flex;
    }

    .okvir{
        position: absolute; 
        height: 700px;
        z-index: 2;
        margin-left: -125px;
        /* top: 100.05vh; */
    }

    @media screen and (max-width: 1260px) {
        .aboutImage{
            display: none;
        }

        .aboutUs{
            justify-content: center;
            
        }

        .aboutSection{
            width: 90%;
            margin-bottom: 7%;
        }

        #genexAbout{
            margin-top: 2%;
            height: 60%;
        }
    }

    @media screen and (max-width: 700px) {
        
        #genexAbout{
            margin-top: 5%;
            height: 75%;
        }
    }

</style>